import Head from "next/head"

import getPages from "@/services/page"
import { IPageTemplate } from "@/types/contentful"
import { useUrl } from "@/hooks/useUrl"

import ContentPage from "@/components/ContentPage/ContentPage"
import getSettings from "@/services/settings"

/**
 * Nextjs can't find '/' slug in getStaticPaths
 * so we need to create this page manually
 */
export default function Home(props: { page: IPageTemplate }) {
  const canonicalUrl = useUrl()

  return (
    <>
      <Head>
        <title>{props.page.fields.seoTitle}</title>
        {props.page.fields.seoDescription && (
          <meta name="description" content={props.page.fields.seoDescription} />
        )}
        {props.page.fields.seoKeywords && (
          <meta name="keywords" content={props.page.fields.seoKeywords} />
        )}
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <main>
        <ContentPage {...props} />
      </main>
    </>
  )
}

export async function getStaticProps(context: any) {
  const pagePromise = getPages("/") as Promise<IPageTemplate>
  const settingsPromise = getSettings()
  const [settings, page] = await Promise.all([settingsPromise, pagePromise])

  if (!page) {
    return {
      notFound: true,
    }
  }

  page.fields.sections = page.fields.sections.map((section: any) => {
    if (section.sys.contentType.sys.id !== "sectionItems") {
      return section
    }

    section.fields.links = section.fields.links.map((item: any) => {
      if (item.fields?.sections) {
        item.fields.sections = null
      }
      return item
    })

    return section
  })

  return {
    props: { page, settings },
  }
}
